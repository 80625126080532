import React from "react";
import { actions } from "primarycare-binder";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// import { Routes } from "../../router/routes";
import {
  Container,
  Div,
  Icons,
  SideMenu,
  Text,
  Image,
  Divider,
  Button,
  H6,
} from "qdm-component-library";
import ReactToPrint from "react-to-print";
import CancelBookingPage from "../../components/cancelBooking/index";
import { Routes } from "../../router/routes";
import { convertTime, makeName, ValidateEmail, getImgUrl, utcTOLocal, UIColor } from "../../utils";
import { PatientDetails } from "./patientDetails";
import { AppointmentTable } from "./appointmentTable";
import { AppointmentSummaryPDF } from "../";
import EmailDialog from "./dialog";
import videoFilled from "../../assets/img/AVC/videoFilled.svg";
import videoOutline from "../../assets/img/AVC/videoOutline.svg";
import payment from "../../assets/img/AVC/payment.svg";
import { GoogleCalander } from "../../components/googleCalendar";
import {DownloadIcon,PrintIcon,ShareIcon} from "../../components/svg"
// import { Gustuserconfirm } from "../../assets/img/gustuserconfirm.js";
class ConfirmBook extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cancelbooking: false,
      cancelbookingreason: {},
      cancelbookings: 0,
      appointmentInfo: {},
      appArr: [],
      modal: false,
      err: false,
      emailId: "",
      isLoading: false,
      loading: false,
      genInst: {},
    };
  }
  changeState = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  fetchExternalCall = async (postUrl, redirectUrl) => {
    const appInfo = JSON.stringify(this.state.appointmentInfo);
    await fetch(postUrl, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: appInfo
    }).catch(console.error);
    if (redirectUrl) {
      window.parent.location.href = redirectUrl + appInfo;
    }
  }

  componentDidMount() {
    if (!this.props.multipleAppointments) {
      this.fetchInfo();
    } else {
      let info = {};
      const data = this.props.location?.state?.appointmentInfo?.map(
        (app, i) => {
          if (!!app?.payload?.error) {
            return undefined;
          } else {
            info =
              app?.payload?.data?.appointment_response?.appointment?.Result?.[0]
                ?.properties?.doc || {};
            return {
              index: i,
              appointmentNumber: `${app?.payload?.data?.appointment_response?.appointment?.Result?.[0]?.properties?.doc?.appno}`,
              dateTime:
                app?.payload?.data?.appointment_response?.appointment
                  ?.Result?.[0]?.properties?.doc?.start,
            };
          }
        }
      );
      this.fetchInfo(info.id, data);
    }
  }

  async fetchInfo(id, data) {
    if (!id) {
      const data = await this.props.APPOINTMENT_READ({
        appointmentId: this.props.appointmentId ,
      });
      this.changeState("appointmentInfo", data?.payload?.data || {});
    } else {
      const info = await this.props.APPOINTMENT_READ({
        appointmentId: id ,
      });
      this.setState({
        ...this.state,
        appointmentInfo: info?.payload?.data || {},
        appArr: data.filter((d) => !!d),
      });
    }
    const genInst = await this.props.GENERAL_INSTRUCTION({
      org_id: this.state.appointmentInfo?.orgid?._id || "",
    });
    this.setState({
      ...this.state,
      genInst: genInst?.payload?.data,
    })
    const {
      fromQueryPage = "",
      successUrl = "",
      redirectUrl = "",
    } = (this.props?.location?.state || {})
    if (fromQueryPage && successUrl) {
      this.fetchExternalCall(successUrl, redirectUrl);
    }
  }

  shouldComponentUpdate(props, state) {
    return true;
  }
  styles = {
    container: {
      background: "#FFFFFF",
      boxShadow: "0px 4px 8px #0000001A",
      padding: 30,
      maxWidth: "570px",
      borderRadius: 16,
    },
    tickSvg: {
      width: 32,
      height: 32,
      margin: "auto auto 10px",
    },
    appointmentBooking: {
      color: UIColor.success.color,
      fontSize: 18,
      marginBottom: 10,
      textAlign: "center",
    },
    confirmation: {
      color: UIColor.tertiaryText.color,
      fontSize: 12,
      textAlign: "center",
    },
    wrapper: {
      border: "2px dashed rgb(239 239 239)",
      borderRadius: 8,
      display: "flex",
      alignItems: "center",
      margin: "20px 0px",
    },
    h6: {
      color: UIColor.secondaryText.color,
      fontSize: 14,
      margin: 0,
    },
    span: {
      color: UIColor.tertiaryText.color,
      fontSize: 12,
    },
    barCode: {
      width: 100,
      height: 100,
      border: "2px dashed rgb(239 239 239)",
      padding: 5,
      marginBottom: 10,
      borderRadius: 8,
    },
    videoIcon: {
      width: 80,
      height: 80,
      background: "#73B9FF",
      marginBottom: 10,
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    flexColumn: {
      display: "flex",
      flexDirection: "column",
      padding: 10,
    },
    flexRow: {
      display: "flex",
      alignItems: "center",
    },
    divider: {
      margin: "10px",
      opacity: 0.5,
    },
    iconSpan: {
      color: UIColor.secondaryButtonColor.color,
      marginLeft: "6px",
      fontSize: 12,
    },
    outlinedButton: {
      borderColor: UIColor.primaryColor.color,
      // color: UIColor.primaryColor.color,
      // backgroundColor: "#fff",
      
      backgroundColor: UIColor.primaryColor.color,
      borderRadius: 8,
      padding: "8px 15px",
      minWidth: 100,
      fontSize: 12,
      marginRight: 10,
      "&:focus": {
        color: UIColor.primaryColor.color,
      }
    },
    containedButton: {
      backgroundColor: UIColor.primaryColor.color,
      borderColor: UIColor.primaryColor.color,
      borderRadius: 8,
      padding: "8px 15px",
      minWidth: 100,
      fontSize: 12,
    },
    clickHere: {
      margin: 0,
      marginLeft: 10,
      color: UIColor.primaryColor.color,
      cursor: "pointer",
      fontSize: 12,
    },
    gustuser: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      border: "1px solid #3BB213",
      width: "80%",
      padding: "6px 8px",
      backgroundColor: "#F0FCEF",
      borderRadius: "8px",
      fontSize: 12,
      color: "#258007",
      marginTop: "4%",
    },
    multipleAppointmentsWrapper: {
      border: "1px solid #DEE5EC",
      borderRadius: 8,
      display: "flex",
      padding: "5px 0px",
      margin: "20px 0px",
    },
    veticalDiv: {
      width: 0.5,
      backgroundColor: "#DEE5EC",
    },
  };
  handlesidebarCancelbooking = () => {
    this.setState({
      cancelbooking: !this.state.cancelbooking,
    });
  };
  handleSelectCancelbooking = (v) => {
    this.setState({
      cancelbookingreason: v,
    });
  };
  handlecahngeCancelbooking = async (v, number) => {
    if (v === "back") {
      this.setState({
        cancelbookings: 0,
      });
      return;
    }
    if (!v) {
      this.handlesidebarCancelbooking();
      if (this.props?.location?.state?.fromQueryPage) {
        this.props.LOGGED_USER_INFO({});
        localStorage.removeItem("query_token");
        window.location.href = Routes.signIn;
      } else {
        window.location.href = Routes.appointmentsListingPage;
      }
      // this.setState({
      //   cancelbookings: 0,
      // });
      return;
    }
    const mobile = number;
    if (v === 1) {
      await this.props.GENERATE_OTP({ mobileNo: mobile });
    }
    this.setState({
      cancelbookings: v,
    });
  };

  getPractionerName(val) {
    if ((val?.resourcetype || "")?.toLowerCase() === "practitioner") {
      return makeName(val?.resourcecode?.name?.[0] ?? {});
    }
    return val?.resourcecode?.shortdesc;
  }

  tableHead = ["S.no", "appointment number", "Date & Time"];

  appointmentSummaryPDF = async () => {
    await this.downloadPDF("pdf");
  };

  downloadPDF = (divName) => {
    return new Promise(async (resolve, reject) => {
      const html2pdf = require("html2pdf.js");
      var dateObj = new Date();
      var month = dateObj.getUTCMonth() + 1; //months from 1-12
      var day = dateObj.getUTCDate();
      var year = dateObj.getUTCFullYear();
      var newdate = year + "_" + month + "_" + day;
      var printContents = document.getElementById(divName).innerHTML;
      var opt = {
        filename: `appointment_booking_${newdate}.pdf`,
        // image: { type: "jpeg", quality: 0.95 },
        html2canvas: {
          dpi: 72,
          letterRendering: true,
          useCORS: true,
          proxy: true,
        },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        // pdfCallback: pdfCallback
      };
      html2pdf().set(opt).from(printContents).save();
    });
  };

  //for Base6Url
  getBase64Url = (divName) => {
    return new Promise(async (resolve, reject) => {
      const html2pdf = require("html2pdf.js");
      var dateObj = new Date();
      var month = dateObj.getUTCMonth() + 1; //months from 1-12
      var day = dateObj.getUTCDate();
      var year = dateObj.getUTCFullYear();
      var newdate = year + "_" + month + "_" + day;
      var printContents = document.getElementById(divName).innerHTML;
      var opt = {
        filename: `appointment_booking_${newdate}.pdf`,
        //image: { type: "jpeg", quality: 1 },
        html2canvas: {
          dpi: 72,
          letterRendering: true,
          useCORS: true,
          proxy: true,
        },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        // pdfCallback: pdfCallback
      };
      await html2pdf()
        .from(printContents)
        .set(opt)
        .toPdf()
        .output("datauristring")
        .then((val) => {
          resolve(val);

          this.props.SHAREEMAIL({
            emails: this.state.emailId,
            file_url: val,
          });
          this.setState({ loading: false, emailId: "" });
          this.handleClose();
        })
        .catch((err) => {
          resolve(null);
        });
    });
  };

  appointmentDetails = () => {
    if (this.props?.multipleAppointments) {
      return this.state?.appArr?.map((l) => {
        return {
          ...l,
          dateTimeConverted: utcTOLocal(l?.dateTime, "DD MMM YYYY | hh:mm a"),
          appointmentId: l?.appointmentNumber,
        };
      });
    } else {
      return [
        {
          dateTimeConverted:
            this.state?.appointmentInfo?.resourcetype?.toLowerCase() === "practitioner"
              ? utcTOLocal(
                this.state.appointmentInfo?.start || 0, "DD MMM YYYY | hh:mm a"
              )
              : `${utcTOLocal(
                this.state.appointmentInfo?.start || 0, "DD MMM YYYY"
              )} | ${utcTOLocal(
                this.state.appointmentInfo?.start || 0, "hh:mm a"
              )} - ${utcTOLocal(
                this.state.appointmentInfo?.end || 0, "hh:mm a"
              )}`,
          // time:'09:00 am',
          appointmentId: `${this.state.appointmentInfo?.appno || "---"}`,
        },
      ];
    }
  };

  appointmentDownloadPDFData = () => {
    const data = {
      personDetails: {
        phoneNo:
          this.state?.appointmentInfo?.AppointmentNotification?.[0]?.mobile ??
          "",
        emailId:
          this.state.appointmentInfo?.AppointmentNotification?.[0]
            ?.patientEmail ?? "",
        patientName: makeName(this.state.appointmentInfo?.Person?.[0]?.name?.[0]),
        age: `${convertTime(
          this.state.appointmentInfo?.Person?.[0]?.birthDay,
          "DD/MM/YYYY",
          "years",
          "diff"
        )} Yrs`,
        gender: this.state.appointmentInfo?.Person?.[0]?.gender,
        profile_pic: getImgUrl(this.state.appointmentInfo?.Person?.[0]?.photo?.[0]?.fileid) ||
          undefined,
      },
      doctorDetails: {
        doctorName: this.getPractionerName(this.state.appointmentInfo),
        speciality:
          this.state.appointmentInfo?.Speciality?.[0]?.coding?.[0]?.display ||
          " ",
        address:
          this.state.appointmentInfo?.orgid?.name || "",
        profile_pic: getImgUrl(this.state.appointmentInfo?.resourcecode?.photo?.[0]?.fileid) ||
          undefined,
      },
      hospitalDetails: {
        hospitalName:
          this.state.appointmentInfo?.orgid?.name ?? "",
        hospitalAddress: {
          street: this.state.appointmentInfo?.orgid?.address?.[0]?.line ?? "",
          city: this.state.appointmentInfo?.orgid?.address?.[0]?.city?.geogLevelName ?? "",
          state: this.state.appointmentInfo?.orgid?.address?.[0]?.state?.geogLevelName ?? "",
          pincode: this.state.appointmentInfo?.orgid?.address?.[0]?.postalCode?.geogLevelName ?? "",
          phoneNo: this.state.genInst?.orgContact ?? this.state.appointmentInfo?.orgid?.contact?.[0]?.telecom?.[0]?.value ?? "",
        },
      },
      appointmentDetails: this.appointmentDetails(),
      isMulti: this.props?.multipleAppointments,
      generalInstructions: this.state.genInst?.generalInstruction || [],
      specialInstructions: this.state.genInst?.appointmentInstruction || []
      // appointmentDetails:{
      //   date:'07 Apr 2021',
      //   time:'09:00 am',
      //   appointmentId:'AP-123456',
      // }
    };

    return data;
  };

  handleClickOpen = () => {
    this.setState({
      modal: true,
    });
  };

  handleClose = () => {
    this.setState({
      modal: false,
    });
  };

  onChange = (v) => {
    this.setState({
      emailId: v,
      err: false,
    });
  };
  handleSend = () => {
    if (ValidateEmail(this.state.emailId) && this.state.emailId) {
      this.setState({ loading: true });
      this.getBase64Url("pdf");
    } else {
      this.setState({
        err: true,
      });
    }
  };

  handleOnBeforeGetContent = () => {
    this.setState({ isLoading: true });
  };

  handleAfterPrint = () => {
    this.setState({ isLoading: false });
  };

  render() {
    const {
      container,
      tickSvg,
      appointmentBooking,
      confirmation,
      wrapper,
      h6,
      span,
      barCode,
      videoIcon,
      outlinedButton,
      flexColumn,
      flexRow,
      divider,
      iconSpan,
      containedButton,
      clickHere,
      multipleAppointmentsWrapper,
      veticalDiv,
      // gustuser,
    } = this.styles;
    const isVideo = this.state.appointmentInfo?.consultationMode?.toLowerCase?.() === 'video consultation';
    const { cancelbooking, cancelbookingreason, cancelbookings } = this.state;
    return (
      <Container
        name="container"
        fluid={false}
        inLineStyles={container}
        id="confirmbook_parent_container"
        //style={{ margin: 20 }}
      >
        <Div id="confirmbook_parent_div">
          <Div id="confirmbook_intstagram_verification_div" style={tickSvg}>
            <Image
              src="/images/icons8-instagram-verification-badge.svg"
              alt="tick"
              id="instagram_verification"
            />
          </Div>
          <H6
            id="your_appointment_has_been_h6"
            className="pc_semibold"
            style={appointmentBooking}
          >
            Your appointment has been{" "}
            {this.props.location?.state?.modfied ? "modified" : "booked"} !
          </H6>
          <H6 id="Confirmation_h6" className="pc_regular" style={confirmation}>
            Confirmation has been sent to your{" "}
            {this.state?.appointmentInfo?.AppointmentNotification?.[0]?.mobile}{" "}
            {this.state.appointmentInfo?.AppointmentNotification?.[0]?.patientEmail && "&"}
            <br />
            {
              this.state.appointmentInfo?.AppointmentNotification?.[0]
                ?.patientEmail
            }
          </H6>
          {/* <H6 className="pc_regular" style={gustuser}>
            <span style={{ marginRight: "2%" }}>
              <Gustuserconfirm />
            </span>{" "}
            You're a registered user now, you can log in using
            <br /> your mobile number (9894541371).
          </H6> */}

          {this.props?.multipleAppointments ? (
            <Div id="confirmbook_patient_div">
              <Div
                id="confirmbook_patient_sub_div"
                style={multipleAppointmentsWrapper}
              >
                <PatientDetails
                  parent_id={`confirmbook`}
                  // url="https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg"
                  name={this.getPractionerName(this.state.appointmentInfo)}
                  title={
                    this.state.appointmentInfo?.Speciality?.[0]?.coding?.[0]
                      ?.display || " "
                  }
                  url={getImgUrl(this.state.appointmentInfo?.resourcecode?.photo?.[0]?.fileid)}
                  subTitle={
                    this.state.appointmentInfo?.orgid?.name || ""
                  }
                />
                <Div id="confirmbook_patient_vetical_div" style={veticalDiv} />
                <PatientDetails
                  parent_id={`confirmbook`}
                  url={getImgUrl(this.state.appointmentInfo?.Person?.[0]?.photo?.[0]?.fileid)}
                  // url="https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg"
                  name={makeName(this.state.appointmentInfo?.Person?.[0]?.name?.[0])}
                  title={`${convertTime(
                    this.state.appointmentInfo?.Person?.[0]?.birthDay,
                    "DD/MM/YYYY",
                    "years",
                    "diff"
                  )} Yrs | ${this.state.appointmentInfo?.Person?.[0]?.gender}`}
                  subTitle={
                    this.state.appointmentInfo?.AppointmentNotification?.[0]
                      ?.mobile
                  }
                />
              </Div>
              <Div
                id="confirmbook_appointment_div"
                style={{ marginBottom: 20 }}
              >
                <AppointmentTable
                  parent_id={"confirmbook"}
                  tableHead={this.tableHead}
                  tableData={this.state.appArr}
                />
              </Div>
            </Div>
          ) : (
            <Div id="confirmbook_image_div" style={wrapper}>
              <Div
                id="confirmbook_image_barcode_div"
                style={{ ...flexColumn, alignItems: "center" }}
              >
                {
                  (!isVideo)
                    ? (
                      <Div id="confirmbook_barcode_div" style={barCode}>
                        <Image
                          id="confirmbook_barcode_image"
                          alt="Image"
                          // src="https://upload.wikimedia.org/wikipedia/commons/d/d0/QR_code_for_mobile_English_Wikipedia.svg"
                          src={`https://api.qrserver.com/v1/create-qr-code/?size=170x170&data=${this.state.appointmentInfo?.id}`}
                        />
                      </Div>
                    )
                    : (
                      <Div id="confirmbook_video_div" style={videoIcon}>
                        <Image
                          id="confirmbook_video_image"
                          alt="Image"
                          inLineStyles={{
                            height: "30px",
                            width: "30px"
                          }}
                          // src="https://upload.wikimedia.org/wikipedia/commons/d/d0/QR_code_for_mobile_English_Wikipedia.svg"
                          src={videoOutline}
                        />
                      </Div>
                    )
                }
                <H6
                  id="confirmbook_datetime_format_h6"
                  className="pc_medium"
                  style={h6}
                >
                  {
                    // convertTime(this.state.appointmentInfo?.start || 0, "DD/MM/YYYY HH:mm:ss", "DD MMM YYYY | hh:mm a")
                    this.state?.appointmentInfo?.resourcetype?.toLowerCase() === "practitioner"
                      ? utcTOLocal(
                        this.state.appointmentInfo?.start || 0, "DD MMM YYYY | hh:mm a"
                      )
                      : `${utcTOLocal(
                        this.state.appointmentInfo?.start || 0, "DD MMM YYYY"
                      )} | ${utcTOLocal(
                        this.state.appointmentInfo?.start || 0, "hh:mm a"
                      )} - ${utcTOLocal(
                        this.state.appointmentInfo?.end || 0, "hh:mm a"
                      )}`
                  }
                </H6>
                <H6
                  id="confirmbook_appointmnet_info_h6"
                  className="pc_regular"
                  style={{ ...span, margin: "5px 0px" }}
                >
                  {this.state.appointmentInfo?.appno || "---"}
                  {/* Data For Price */}
                  {
                    (!this.props.hidden && isVideo) &&
                    <span
                      id="confirmbook_appointmnet_info_span"
                      className="pc_regular"
                      style={{ ...span, color: UIColor.error.color }}
                    >
                      <span style={{...span, color: UIColor.tertiaryText.color}}>{", "}</span>
                      {"Not-Paid"}
                    </span>
                  }
                </H6>
                {isVideo &&
                  <Div
                    style={{
                      background: "#FFF9F2 0% 0% no-repeat padding-box",
                      borderRadius: "20px",
                      padding: "3px 12px",
                    }}
                  >
                    <H6
                      id="confirmbook_appointmnet_info_video_h6"
                      className="pc_regular"
                      style={{
                        ...span,
                        margin: "5px 0px",
                        color: UIColor.secondaryColor.color,

                      }}
                    >
                      Passcode : {this?.state?.appointmentInfo?.avc?.passCode}
                    </H6>
                  </Div>
                }
              </Div>
              <Div
                id="confirmbook_dashed_div"
                style={{
                  borderLeft: "1px dashed #DEE5EC",
                  flex: 1,
                }}
              >
                <PatientDetails
                  parent_id={"confirmbook"}
                  // url="https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg"
                  name={this.getPractionerName(this.state.appointmentInfo)}
                  title={
                    this.state.appointmentInfo?.Speciality?.[0]?.coding?.[0]
                      ?.display || " "
                  }
                  subTitle={
                    this.state.appointmentInfo?.orgid?.name || ""
                  }
                  url={getImgUrl(this.state.appointmentInfo?.resourcecode?.photo?.[0]?.fileid)}
                />
                <Divider
                  id="confirmbook_patient_bottom_divider"
                  style={divider}
                />
                <PatientDetails
                  parent_id={"confirmbook"}
                  // url="https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg"
                  name={makeName(this.state.appointmentInfo?.Person?.[0]?.name?.[0])}
                  title={`${convertTime(
                    this.state.appointmentInfo?.Person?.[0]?.birthDay,
                    "DD/MM/YYYY",
                    "years",
                    "diff"
                  )} Yrs | ${this.state.appointmentInfo?.Person?.[0]?.gender}`}
                  subTitle={
                    this.state.appointmentInfo?.AppointmentNotification?.[0]
                      ?.mobile
                  }
                  url={getImgUrl(this.state.appointmentInfo?.Person?.[0]?.photo?.[0]?.fileid)}
                />
              </Div>
            </Div>
          )}
          <Div
            id="confirmbook_summartpdf_parent_div"
            style={{ ...flexRow, justifyContent: "space-between", margin:"0px 10px" }}
          >
            <Div
              id="confirmbook_summartpdf_sub_div"
              style={{ ...flexRow, cursor: "pointer" }}
            >
              {/* <Icons
                id="confirmbook_summartpdf_download_icon"
                fontIcon="download"
                ariaHidden="true"
                size="small"
                style={{ color: UIColor.secondaryButtonColor.color }}
              /> */}
              <DownloadIcon />
              <Text
                id="confirmbook_summartpdf_span"
                className="pc_regular"
                style={iconSpan}
                onClick={() => this.appointmentSummaryPDF()}
              >
                Appointment Summary
              </Text>
            </Div>
            <Div
              id="confirmbook_invoice_div"
              style={{ ...flexRow, cursor: "pointer" }}
            >
              {/* <Icons
                id="confirmbook_invoice_download_icon"
                fontIcon="download"
                ariaHidden="true"
                size="small"
                style={{ color: UIColor.secondaryButtonColor.color }}
              /> */}
              <DownloadIcon />
              <Text
                id="confirmbook_invoice_span"
                className="pc_regular"
                style={iconSpan}
              >
                Invoice
              </Text>
            </Div>
            <ReactToPrint
              onBeforeGetContent={this.handleOnBeforeGetContent}
              onAfterPrint={this.handleAfterPrint}
              trigger={() => (
                <Div
                  id="confirmbook_print_div"
                  style={{ ...flexRow, cursor: "pointer" }}
                //onClick={() => this.handlePrint()}
                >
                  {/* <Icons
                    id="confirmbook_print_icon"
                    fontIcon="print"
                    ariaHidden="true"
                    size="small"
                    style={{ color: UIColor.secondaryButtonColor.color }}
                  /> */}
                  <PrintIcon />
                  <Text
                    id="confirmbook_print_span"
                    className="pc_regular"
                    style={iconSpan}
                  >
                    {this.state.isLoading ? "Enabling Print..." : "Print"}
                  </Text>
                </Div>
              )}
              content={() => this.componentRef}
            />

            <Div
              id="confirmbook_share_div"
              style={{ ...flexRow, cursor: "pointer" }}
              onClick={() => this.handleClickOpen()}
            >
              {/* <Icons
                id="confirmbook_share_icon"
                fontIcon="share"
                ariaHidden="true"
                size="small"
                style={{ color: UIColor.secondaryButtonColor.color }}
              /> */}
              <ShareIcon />
              <Text
                id="confirmbook_share_span"
                className="pc_regular"
                style={iconSpan}
              >
                Share
              </Text>
            </Div>
          </Div>
          {/* {
            (this.props.hidden && isVideo) && (
              <>
                <Divider
                  id={`video_app_payment_divder`}
                  key={"1"}
                  textWidth={"50"}
                  textColor={"#000"}
                  borderColor={UIColor.lineBorderFill.color}
                  borderWidth={"1"}
                  orientation={"horizontal"}
                  variant={"middle"}
                />
                <Div
                  id={`video_app_payment_div`}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Div
                    id={`video_app_payment_sub_div`}
                    style={{
                      display: "flex",
                    }}
                  >
                    <Image
                      id="confirmbook_payment_image"
                      alt="Image"
                      inLineStyles={{
                        height: "30px",
                        width: "30px"
                      }}
                      // src="https://upload.wikimedia.org/wikipedia/commons/d/d0/QR_code_for_mobile_English_Wikipedia.svg"
                      src={payment}
                    />
                    <H6
                      id="confirmbook_video_payemtn_msg"
                      className="pc_regular"
                      style={{
                        color: UIColor.primaryText.color,
                        fontSize: "12px",
                        width: "50%",
                        marginLeft: "15px",
                        fontWeight: 600
                      }}
                    >

                      Would you like to pay for the appointment now?
                    </H6>
                  </Div>
                  <Button
                    id="confirmbook_video_payment_pay_now"
                    className="pc_medium"
                    inLineStyles={{
                      ...containedButton,
                    }}
                  >
                    Pay Now
                  </Button>
                </Div>
              </>
            )
          } */}
          <div style={{ margin: "30px 0px 0px", textAlign: "center" }}>
            <GoogleCalander appointmentData={this.state} />
          </div>
          {/* {(!this.props.hidden && isVideo && !this.props.multipleAppointments) && (
            <>
              <Divider
                id={`video_app_divder`}
                key={"1"}
                textWidth={"50"}
                textColor={"#000"}
                borderColor={UIColor.lineBorderFill.color}
                borderWidth={"1"}
                orientation={"horizontal"}
                variant={"middle"}
              />
              <Div
                id="confirmbook_video_div"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <Image
                  id="confirmbook_video_div_icon"
                  alt="Image"
                  inLineStyles={{
                    height: "30px",
                    width: "30px",
                    marginBottom: "10px",
                  }}
                  // src="https://upload.wikimedia.org/wikipedia/commons/d/d0/QR_code_for_mobile_English_Wikipedia.svg"
                  src={videoFilled}
                />
                <H6
                  id="confirmbook_video_message"
                  className="pc_regular"
                  style={{ ...span, ...flexRow }}
                >
                  Do you want to join consultation meeting link now?
                </H6>
                <Div
                  id="confirmbook_video_link"
                  className="pc_regular"
                  style={{
                    ...span,
                    marginTop: "10px",
                    color: UIColor.primaryColor.color,
                    fontStyle: "italic",
                    width: "60%",
                    wordWrap: "break-word",
                    cursor: "pointer"
                  }}
                  onClick={() => {
                    window.open(
                      this.state.appointmentInfo?.avc?.url,
                      "_blank"
                    )
                  }}
                >
                  {(this.state.appointmentInfo?.avc?.url || "")}
                </Div>
                <Div
                  id="confirmbook_video_password"
                  className="pc_regular"
                  style={{
                    ...span,
                    marginTop: "10px",
                    color: UIColor.secondaryColor.color,
                  }}
                >
                  Passcode : {this?.state?.appointmentInfo?.avc?.passCode}
                </Div>
                <Button
                  id="confirmbook_video_join+now"
                  className="pc_medium"
                  onClick={() => {
                    window.open(
                      this.state.appointmentInfo?.avc?.url,
                      "_blank"
                    )
                  }}
                  inLineStyles={{
                    ...containedButton,
                    marginTop: "10px",
                  }}
                >
                  Join Now
                </Button>
              </Div>
              <Divider
                id={`video_app_divder`}
                key={"1"}
                textWidth={"50"}
                textColor={"#000"}
                borderColor={UIColor.lineBorderFill.color}
                borderWidth={"1"}
                orientation={"horizontal"}
                variant={"middle"}
              />
            </>
          )} */}
          {!this.props.hidden && (
            <Div
              id="confirmbook_backto_home_div"
              style={{
                ...flexRow,
                justifyContent: "center",
                margin: "30px 0px",
              }}
            >
              <Button
                id="confirmbook_backto_home_buttom"
                className="pc_medium"
                onClick={() => {
                  if (this.props?.location?.state?.fromQueryPage) {
                    this.props.LOGGED_USER_INFO({});
                    localStorage.removeItem("query_token");
                    window.parent.close();
                  } else {
                    this.props.history.push(Routes.appointmentsListingPage)
                  }
                }}
                inLineStyles={{
                  ...(isVideo ? outlinedButton : containedButton),
                  marginRight: 0,
                }}
              >
                Back to Home
              </Button>
            </Div>
          )}
          {/* {!this.props.hidden && (
            <Div id="confirmbook_want_tocancel_div">
              <H6
                id="confirmbook_want_tocancel_h6"
                className="pc_regular"
                style={{ ...span, ...flexRow, justifyContent: "center" }}
              >
                Want to cancel appointment ?
                <H6
                  id="confirmbook_clickhere_h6"
                  style={clickHere}
                  onClick={() => this.handlesidebarCancelbooking()}
                >
                  Click here
                </H6>
              </H6>
            </Div>
          )} */}
        </Div>
        <SideMenu
          id="confirmbook_cancel_parent_sidemenu"
          open={cancelbooking}
          direction="right"
          width={435}
          color="default"
          onClose={() => this.handlesidebarCancelbooking()}
        >
          <CancelBookingPage
            parent_id={"confirmbook"}
            cancelbookingreason={cancelbookingreason}
            appointmentId={this.props.appointmentId}
            cancelbookings={cancelbookings}
            handleSelectCancelbooking={this.handleSelectCancelbooking}
            handlecahngeCancelbooking={this.handlecahngeCancelbooking}
          />
        </SideMenu>
        <AppointmentSummaryPDF
          parent_id={"confirmbook"}
          data={this.appointmentDownloadPDFData()}
          ref={(el) => (this.componentRef = el)}
        />
        <EmailDialog
          value={this.state.emailId}
          onChange={this.onChange}
          handleClose={this.handleClose}
          open={this.state.modal}
          handleSend={this.handleSend}
          error={this.state.err}
          loading={this.state.loading}
          helperText={"Please Enter Vaild Email ID"}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  appointmentInfo: state?.appointmentReducer?.readAppointment,
});

export default connect(mapStateToProps, actions)(withRouter(ConfirmBook));